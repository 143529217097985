import axios from "axios";

const MAP_URL = "https://maps.googleapis.com/maps/api/js?key=";

export default class MapService {
  init() {
    let mapScript = document.createElement("script");
    return new Promise(resolve => {
      axios.get("/api/v1/maps/api").then(response => {
        let script = MAP_URL + response.data;
        mapScript.setAttribute("src", script);
        document.head.appendChild(mapScript);

        var checkExist = setInterval(function() {
          if (window.google && window.google.maps) {
            clearInterval(checkExist);
            resolve(window.google);
          }
        }, 100);
      });
    });
  }

  getPath(startLat, startLng, endLat, endLng) {
    return axios.get(
      `/api/v1/path?startLat=${startLat}&startLng=${startLng}&endLat=${endLat}&endLng=${endLng}`
    );
  }
}
