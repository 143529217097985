<!-- see https://medium.com/founders-factory/building-a-custom-google-map-component-with-vue-js-d1c01ddd0b0a -->

<template>
  <div class="grow fill-height wrapper">
    <div id="result-row">
      <div class="md-layout md-gutter">
        <div
          class="md-layout-item md-size-20 md-medium-size-40 md-xsmall-size-60"
        >
          The distance is
        </div>
        <div
          class="md-layout-item md-size-20 md-medium-size-40 md-xsmall-size-40"
        >
          ~
          <span class="strong"
            >{{ result.lengthMiles }} miles ({{ result.lengthKm }} km)</span
          >.
        </div>
        <div
          class="md-layout-item md-size-20 md-medium-size-40 md-xsmall-size-60"
        >
          The travel time is
        </div>
        <div
          class="md-layout-item md-size-20 md-medium-size-40 md-xsmall-size-40"
        >
          ~
          <span class="strong">{{ result.travelTime }} min</span>.
        </div>
      </div>
    </div>
    <div id="google-map" class="grow"></div>
    <div v-if="showWaiting">
      <div id="overlay">
        <PulseLoader />
      </div>
    </div>
    <md-snackbar
      :md-duration="8000"
      :md-active.sync="snackbarShow"
      md-persistent
    >
      <span>{{ snackbarMessage }}</span>
    </md-snackbar>
  </div>
</template>

<script>
import MapService from "./MapService.js";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  name: "google-map",
  components: {
    PulseLoader,
  },
  data: function () {
    return {
      showWaiting: true,
      showResult: false,
      snackbarShow: false,
      snackbarMessage: "",
      result: {
        lengthMiles: 0,
        lengthKm: 0,
        travelTime: 0,
      },
    };
  },
  mounted: function () {
    this.$mapService = new MapService();
    this.$mapService.init().then((google) => {
      this.$map = new google.maps.Map(document.getElementById("google-map"), {
        restriction: {
          latLngBounds: {
            north: 26.871437,
            south: 26.334196,
            west: -82.322283,
            east: -81.829024,
          },
          strictBounds: false,
        },
      });

      this.$center = {
        zoom: 12,
        center: new google.maps.LatLng(26.58273, -81.991257),
      };

      this.$polylines = [];
      this.$markers = [];

      this.clear();

      this.showWaiting = false;
      google.maps.event.trigger(this.$map, "resize");
    });
  },
  methods: {
    update: function (start, end) {
      if (start == undefined || end == undefined) {
        this.clear();
      } else {
        this.showWaiting = true;
        this.showResult = false;
        this.snackbarShow = false;
        this.snackbarMessage = "";
        this.clear();

        this.$mapService
          .getPath(start.lat, start.lng, end.lat, end.lng)
          .then((res) => {
            this.result = res.data;
            // If another call meanwhile added a new poly
            this.clear();

            const google = window.google;
            const poly = new google.maps.Polyline({
              path: res.data.path,
              strokeColor: "red",
              strokeOpacity: 1.0,
              strokeWeight: 3,
            });

            this.$polylines.push(poly);
            poly.setMap(this.$map);

            const startMarker = new google.maps.Marker({
              position: res.data.path[0],
              map: this.$map,
              animation: google.maps.Animation.DROP,
            });

            this.$markers.push(startMarker);

            const endMarker = new google.maps.Marker({
              position: res.data.path[res.data.path.length - 1],
              map: this.$map,
              animation: google.maps.Animation.DROP,
            });

            this.$markers.push(endMarker);
            var bounds = new google.maps.LatLngBounds();
            bounds.extend(new google.maps.LatLng(start.lat, start.lng));
            bounds.extend(new google.maps.LatLng(end.lat, end.lng));
            this.$map.fitBounds(bounds);
            this.$map.panToBounds(bounds);
            google.maps.event.trigger(this.$map, "resize");
            this.showResult = true;
            this.showWaiting = false;
          })
          .catch((err) => {
            this.showWaiting = false;
            let msg = "Ups. Something went wrong.";

            if (err.response) {
              msg += " " + err.response.data;
            } else {
              msg += " Please try again. (" + err + ")";
            }

            this.snackbarMessage = msg;
            this.snackbarShow = true;
          });
      }
    },
    clear: function () {
      for (let i = 0; i < this.$polylines.length; i++) {
        this.$polylines[i].setMap(null);
      }

      for (let i = 0; i < this.$markers.length; i++) {
        this.$markers[i].setMap(null);
      }

      this.$polylines = [];
      this.$markers = [];

      if (this.$map) this.$map.setOptions(this.$center);
    },
  },
};
</script>

<style scoped>
#result-row {
  padding: 0px 10px 20px 10px;
  font-size: 16px;
}

#result-row .md-layout-item {
  padding-top: 10px;
}

.strong {
  font-weight: bold;
}

.v-spinner {
  margin: 20px;
  text-align: center;
  vertical-align: middle;
}

#overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.wrapper {
  position: relative;
  min-height: 300px;
}
</style>
