import request from "./request";

export function queryStartAddress(search) {
  return request({
    method: "GET",
    url: "/search?type=address&t=START&q=" + search,
  });
}

export function queryDestinations(search) {
  return request({
    method: "GET",
    url: "/search/?type=poi&t=END&q=" + search,
  });
}

export function queryEnds(search) {
  return request({
    method: "GET",
    url: "/search/?type=address%7Cpoi&t=END&q=" + search,
  });
}

export function queryPointOfInterests(search) {
  return request({
    method: "GET",
    url: "/search?type=poi&t=poi&q=" + search,
  });
}
