<template>
  <div>
    <md-autocomplete
      v-model="input"
      :md-options="options"
      @md-selected="updateSelected"
      @md-changed="updateOptions"
      md-dense
      md-input-placeholder="Where do you want to start?"
      :md-fuzzy-search="false"
      id="start"
      :class="{ 'md-invalid': this.$v.input.$error }"
    >
      <span class="md-error" v-if="!this.$v.input.required"
        >Please enter an address</span
      >

      <template slot="md-autocomplete-item" slot-scope="{ item, term }">
        <md-highlight-text :md-term="term.location">{{
          item.location
        }}</md-highlight-text>
      </template>
    </md-autocomplete>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { queryStartAddress } from "@/api/addressAPI";
import _ from "lodash";

export default {
  name: "InputStart",
  mixins: [validationMixin],
  data: function () {
    return {
      input: "",
      selected: {},
      options: [],
    };
  },
  created() {
    this.updateOptions = _.debounce((searchTerm) => {
      this.options = new Promise((resolve, reject) => {
        if (searchTerm) {
          queryStartAddress(searchTerm).then((response) => {
            resolve(response.data);
          });
        } else reject([]);
      });
    }, 500);
  },
  methods: {
    updateSelected(e) {
      this.input = e.location;
      this.selected = e;
    },
  },
  watch: {
    input: _.debounce(function () {
      this.$v.$reset();
    }, 300),
  },
  validations: {
    input: {
      required,
    },
  },
};
</script>
