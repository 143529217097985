<template>
  <div>
    <div id="container" v-if="type === 'header' && getDevice === 'screen'">
      <ins
        data-revive-zoneid="2"
        data-revive-id="66d0653a890fec018f08a60d53f8fc6c"
      ></ins>
    </div>
    <div id="container" v-if="type === 'header' && getDevice === 'tablet'">
      <ins
        data-revive-zoneid="1"
        data-revive-id="66d0653a890fec018f08a60d53f8fc6c"
      ></ins>
    </div>
    <div id="container" v-if="type === 'header' && getDevice === 'phone'">
      <ins
        data-revive-zoneid="5"
        data-revive-id="66d0653a890fec018f08a60d53f8fc6c"
      ></ins>
    </div>
    <div id="container" v-if="type === 'footer' && getDevice === 'screen'">
      <ins
        data-revive-zoneid="4"
        data-revive-id="66d0653a890fec018f08a60d53f8fc6c"
      ></ins>
    </div>
    <div id="container" v-if="type === 'footer' && getDevice === 'tablet'">
      <ins
        data-revive-zoneid="3"
        data-revive-id="66d0653a890fec018f08a60d53f8fc6c"
      ></ins>
    </div>
    <div id="container" v-if="type === 'footer' && getDevice === 'phone'">
      <ins
        data-revive-zoneid="6"
        data-revive-id="66d0653a890fec018f08a60d53f8fc6c"
      ></ins>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    let bannerScript = document.createElement("script");
    bannerScript.setAttribute(
      "src",
      `${process.env.VUE_APP_AD_DOMAIN}/www/delivery/asyncjs.php`
    );
    document.getElementById("container").appendChild(bannerScript);
  },
  computed: {
    getDevice() {
      if (screen.width <= 640) return "phone";
      if (screen.width > 640 && screen.width < 1200) return "tablet";
      else return "screen";
    },
  },
};
</script>

<style></style>
